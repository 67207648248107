<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담예약관리</h2>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">상담예약등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <!-- <select class="font-16 w-120px h-40px pl-10">
                                <option>센터선택</option>
                                <option>강남센터</option>
                            </select>
                            <select class="font-16 w-120px h-40px ml-10 pl-10">
                                <option>상담구분</option>
                                <option>강남센터</option>
                            </select> -->

                            <input type="text" v-model="value" @keyup.enter="getReservationList()" class="w-200px h-40px pd-00 pl-20" placeholder="상담사명/고객명">
                            <a @click="getReservationList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>상담날짜검색 :
                            <crm-datepicker v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="consEndDate" :parentDate="consEndDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getReservationList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody>
                            <tr>
                                <th>상담날짜</th>
                                <th>상담사</th>
                                <th>고객</th>
                                <th>상품유형</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of resList" :key="index">
                                <td>{{item.consDate.slice(0,10)}}</td>
                                <td>{{item.staffName}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.typeName}}</td>
                                <td><span class="underline pointer" @click="detail(item.idx)">상세정보</span><span v-if="item.reportYn !== 'Y'"> | </span><span class="underline pointer" v-if="item.reportYn !== 'Y'" @click="modify(item.idx)">수정</span><span v-if="item.reportYn !== 'Y'"> | </span><span v-if="item.reportYn !== 'Y'" @click="remove(item.idx)" class="underline pointer">삭제</span></td>
                            </tr>
                            <tr v-if="resList.length === 0">
                                <td colspan="6">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        consStartDate: '', // 기간검색-시작날짜
        consEndDate: '', // 기간검색-종료날짜
        resList: [],
        value: '',

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
    }),

    mounted() {

        if(sessionStorage.getItem('pageNum') !== 'undefined' && sessionStorage.getItem('pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('pageNum'))
        }
        if(sessionStorage.getItem('value') !== 'undefined' && sessionStorage.getItem('value') !== null) {
            this.value = sessionStorage.getItem('value')
        }
        if(sessionStorage.getItem('consStartDate') !== 'undefined' && sessionStorage.getItem('consStartDate') !== null) {
            this.consStartDate = sessionStorage.getItem('consStartDate')
        }
        if(sessionStorage.getItem('consEndDate') !== 'undefined' && sessionStorage.getItem('consEndDate') !== null) {
            this.consEndDate = sessionStorage.getItem('consEndDate')
        }

        this.getReservationList()
    },

    methods: {

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getresList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getresList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getresList()
            }
        },

        // 상담일지 목록
        getReservationList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                consStartDate: this.consStartDate,
                consEndDate: this.consEndDate,
                idxCrmCenter : localStorage.getItem('idxCrmCenter')
            }

            sessionStorage.setItem('pageNum', this.pageNum)
            sessionStorage.setItem('value', this.value)
            sessionStorage.setItem('consStartDate', this.formatDate(this.consStartDate))
            sessionStorage.setItem('consEndDate', this.formatDate(this.consEndDate))

            this.axios.get('/api/v1/cons/list', {
                params: params
            })
            .then(res => {
                    if (res.data.err === 0) {
                        this.resList = res.data.resList

                        if (res.data.resListCount) {
                            this.listLength = res.data.resListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.resList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 상담예약 등록페이지로 이동
        register() {
            this.$router.push('/convention/consult_reserve_register')
        },
        // 상담예약관리 상세정보 페이지로 이동
        detail(idx) {
            this.$router.push(`/convention/consult_reserve_detail?idx=${idx}`)
        },

        // 상담예약 수정페이지로 이동
        modify (idx) {
            this.$router.push(`/convention/consult_reserve_modify?idx=${idx}`)
        },

        // 예약 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {

                this.axios.delete(`/api/v1/cons/${idx}`, 
                    )
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.')
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }

        },
    }
}
</script>
